<template>
	<div id="liveEdit">
		<page-live-edit :is-shop="0"></page-live-edit>
	</div>
</template>

<script>
import PageLiveEdit from '@/components/layout/live/page-live-edit.vue'
import store from '@/store'
import { onBeforeRouteLeave } from 'vue-router'
export default {
	components: {
		PageLiveEdit
	},

	setup(){
		onBeforeRouteLeave((to,from,next)=>{
			if( to.path == '/live/liveList'){
				store.dispatch("setKeepPage",{name:to.name,type:1})
			}else{
				store.dispatch("setKeepPage",{name:to.name,type:2})
			}
			next()
		})
	}
}
</script>

<style lang="scss">

</style>
